import React, {useState} from 'react'
import {Link} from 'gatsby'
import PropTypes from 'prop-types'

import Icon from './Icon'
import logo from '../images/ocf-logo.png'
import lettering from '../images/informarsi-conviene.png'

const Header = ({siteTitle}) => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <header className="header">
      <div className="header__inner">
        <div className="header__logo">
          <Link to="/" onClick={() => setMenuOpen(false)}>
            <img
              className="lettering"
              src={lettering}
              alt="Informarsi Conviene"
            />
            <img className="logo" src={logo} alt="OCF" />
          </Link>
        </div>
        <button
          className={`header__burger ${menuOpen ? 'active' : ''}`}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <nav className={`header__nav ${menuOpen ? 'active' : ''}`}>
          <Link
            onClick={() => setMenuOpen(false)}
            activeClassName="current"
            to="/"
          >
            Home
          </Link>
          <Link
            onClick={() => setMenuOpen(false)}
            activeClassName="current"
            to="/la-consulenza-finanziaria"
          >
            La Consulenza Finanziaria
          </Link>
          <div className="have-submenu">
            <a>
              Video Tutorial <Icon type="arrowDownGreen" />
            </a>
            <div className="submenu">
              <Link
                onClick={() => setMenuOpen(false)}
                activeClassName="current"
                to="/pianificazione-finanziaria"
              >
                Pianificazione finanziaria
              </Link>
              <Link
                onClick={() => setMenuOpen(false)}
                activeClassName="current"
                to="/finanza-ed-impulsivita"
              >
                Finanza ed impulsività
              </Link>
              <Link
                onClick={() => setMenuOpen(false)}
                activeClassName="current"
                to="/diversificazione-del-portafoglio"
              >
                Diversificazione del portafoglio
              </Link>
              <Link
                onClick={() => setMenuOpen(false)}
                activeClassName="current"
                to="/i-rischi-della-speculazione"
              >
                I rischi della speculazione
              </Link>
              <Link
                onClick={() => setMenuOpen(false)}
                activeClassName="current"
                to="/trading-online"
              >
                Trading online
              </Link>
              <Link
                onClick={() => setMenuOpen(false)}
                activeClassName="current"
                to="/rapporto-rischi-rendimenti"
              >
                Il rapporto fra rischi e rendimenti
              </Link>
              <Link
                onClick={() => setMenuOpen(false)}
                activeClassName="current"
                to="/abusivismo-in-finanza"
              >
                L’abusivismo in finanza
              </Link>
              <Link
                onClick={() => setMenuOpen(false)}
                activeClassName="current"
                to="/criptovalute-e-monete-virtuali"
              >
                Criptovalute e monete virtuali
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
