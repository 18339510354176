/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useCallback, useEffect} from 'react'
import PropTypes from 'prop-types'
import {useStaticQuery, graphql} from 'gatsby'
import {CookieBanner} from '@palmabit/react-cookie-law'

import Header from './Header'
import Footer from './Footer'
import {useCookiesConsent} from '../contexts/CookiesContext'
// import "./layout.css"

const Layout = ({children}) => {
  const {update, ...cookies} = useCookiesConsent()

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line global-require
      require('smooth-scroll')('a[href*="#"]')
    }
  }, [])

  const onAcceptStatistics = useCallback(() => {
    update(cookies => ({
      ...cookies,
      analytics: true,
    }))
    if (window.trackGoogleAnalytics) {
      window.trackGoogleAnalytics()
    }
  }, [cookies.analytics])

  const onAcceptMarketing = useCallback(() => {
    update(cookies => ({
      ...cookies,
      profiling: true,
    }))
  }, [cookies.profiling])

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>
      <Footer />
      {!cookies.analytics && !cookies.profiling && (
        <CookieBanner
          message="Questo sito utilizza cookie tecnici e, previo suo consenso, analitici e di profilazione di terze parti. Cliccando “Accetto tutto” acconsente di ricevere tutti i cookie del nostro sito. Cliccando su “Scelta dei cookie” , potrà personalizzare le sue preferenze rispetto ai cookie e acconsentire solo a quelli selezionati. Il rifiuto all’installazione dei cookie di terza parte di profilazione non pregiudica l’utilizzo del Sito, tuttavia potrebbe compromettere la visualizzazione dei contenuti dei siti esterni presenti all’interno del Sito, compresi i contenuti video. Per maggiori informazioni sulle caratteristiche e sulle modalità di disattivazione dei cookie, si rinvia all’informativa estesa (inserire collegamento ipertestuale alla cookie policy del sito)"
          policyLink="/privacy-policy"
          privacyPolicyLinkText="Privacy & Cookie Policy"
          necessaryOptionText="Necessari"
          showPreferencesOption={false}
          statisticsOptionText="Analitici"
          marketingOptionText="Profilazione"
          acceptButtonText="Accetta"
          onAccept={() => console.log('onAccept')}
          onAcceptStatistics={onAcceptStatistics}
          onAcceptMarketing={onAcceptMarketing}
        />
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
