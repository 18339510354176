import React from 'react'

import arrowIcon from '../images/icons/icon-arrow.svg'
import arrowDownIcon from '../images/icons/icon-arrow-down.svg'
import arrowDownIconGreen from '../images/icons/icon-arrow-down-green.svg'
import youtubeIcon from '../images/icons/icon-youtube.svg'
import mouseIcon from '../images/icons/icon-mouse.svg'
import mouseDarkIcon from '../images/icons/icon-mouse-dark.svg'

const TYPES = {
  arrow: arrowIcon,
  arrowDown: arrowDownIcon,
  arrowDownGreen: arrowDownIconGreen,
  youtube: youtubeIcon,
  mouse: mouseIcon,
  mouseDark: mouseDarkIcon,
}

const Icon = ({type, big}) => {
  return (
    <i
      className={`icon ${big ? 'icon--big' : ''}`}
      style={{backgroundImage: 'url(' + TYPES[type] + ')'}}
    ></i>
  )
}

export default Icon
