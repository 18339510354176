import React from 'react'
import {Link} from 'gatsby'
import Icon from './Icon'

import logo from '../images/ocf-logo.png'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__inner">
        <div className="footer__col">
          <Link to="/">
            <img className="footer__logo" src={logo} alt="OCF" />
          </Link>
        </div>
        <div className="footer__col col-2">
          <h4>Sede legale - Dati fiscali</h4>
          <p>
            Organismo di vigilanza e tenuta dell'albo unico dei Consulenti
            Finanziari - OCF
          </p>
          <p>
            <a
              href="https://www.google.it/maps/place/Via+Tomacelli,+146,+00186+Roma+RM/@41.9050234,12.4776507,19.56z/data=!4m5!3m4!1s0x132f6056a263d3e9:0x6067fdc61e1d6900!8m2!3d41.9048862!4d12.4776766"
              target="_blank"
              rel="noreferrer"
            >
              Via Tomacelli 146 - 00186 Roma
            </a>
            {/* <span>
              Tel. <a href="tel:00390645556100">06 45556100</a>
            </span>
            <span>Fax +39 06 45556113</span> */}
          </p>
        </div>
        <div className="footer__col">
          <h4>Informazioni Legali</h4>
          <ul>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/cookie-policy">Cookie Policy</Link>
            </li>
          </ul>
        </div>
        <div className="footer__col">
          <h4>Social</h4>
          <a
            href="https://www.youtube.com/channel/UCM5_kcugmcm7bapvlXWZHuw"
            target="_blank"
            rel="noreferrer"
            title="Youtube"
          >
            <Icon type="youtube" />
          </a>
        </div>
      </div>
      <div className="footer__copyright">
        <span>
          Copyright 2009 - 2019 © OCF Organismo di vigilanza e tenuta dell’albo
          unico dei Consulenti Finanziari - CF 97474000581
        </span>
      </div>
    </footer>
  )
}

export default Footer
